import cloneDeep from 'lodash/cloneDeep'
import type {
  Cart,
  CartItem,
  GiftboxPrice,
  GiftcardAddon,
  GiftcardCartItem,
} from '~/composables/types/cart'
import { sendAddToCart } from '~/composables/useGtmEvents'

const CART_LOCAL_STORAGE_KEY_BASE = 'konfetti-cart-local-session' as string
const GIFTBOX_PRICE: GiftboxPrice = {
  amount: '295',
  currency: 'EUR',
  formatted: '€ 2.95',
}

export const cartLocalStorageKey = (key: string) =>
  `${CART_LOCAL_STORAGE_KEY_BASE}-${key}`

export const getEmptyCart = (): Cart =>
  cloneDeep<Cart>({
    items: [],
    isGiftboxEnabled: false,
    giftboxPrice: GIFTBOX_PRICE,
    updatedAt: null,
    shippingCost: null,
  })

export enum CartKeys {
  GENERAL = 'general-instance',
  EMBEDDED = 'embedded-instance',
}

export const useCart = (key: CartKeys | string) => {
  const cart = useKftLocalStorage<Cart>(
    cartLocalStorageKey(key),
    getEmptyCart()
  )
  const lastRemoved = ref<CartItem | undefined>(undefined)

  const hasGiftcard = computed(() => cartGetters.hasGiftcard(cart.value))

  const findItem = (obj: CartItem): CartItem | undefined => {
    return cart.value.items.find((item: CartItem) => isTheSameItem(obj, item))
  }

  const updateItemQuantity = (
    obj: CartItem,
    newQuantity: number | string
  ): boolean => {
    const item = findItem(obj)
    const availableTicketsQuantity = Number(item?.availableTicketsQuantity) || 0

    if (!item?.quantity) {
      return false
    }

    if (newQuantity === 0) {
      removeItem(obj)
    }

    if (
      !cartGetters.isGiftcard(item) &&
      availableTicketsQuantity < Number(newQuantity)
    ) {
      item.quantity = availableTicketsQuantity
      return false
    }

    item.quantity = Number(newQuantity)

    return true
  }
  const isTheSameItem = (obj: CartItem, item: CartItem): boolean => {
    if (cartGetters.isGiftcard(obj)) {
      return (
        cartGetters.isGiftcard(item) &&
        cartGetters.getGiftcardId(item) === cartGetters.getGiftcardId(obj)
      )
    }

    return cartGetters.getItemId(item) === cartGetters.getItemId(obj)
  }

  const isInCart = (obj: CartItem): boolean => {
    return cart.value.items.some((item: CartItem) => isTheSameItem(item, obj))
  }

  const addItem = (item: CartItem): void => {
    // Send GTM event no matter if it's already in cart
    sendAddToCart(item)

    // check if item is already in cart
    if (isInCart(item)) {
      // find the existing item
      const cartItem = findItem(item)
      // update existing item quantity
      updateItemQuantity(item, (cartItem?.quantity || 0) + item.quantity)

      // update existing item addOn
      if (cartItem && item?.addOn) {
        cartItem.addOn = item.addOn
      }

      return
    }
    // push new item into cart
    cart.value.items.push(item)
  }

  const setLastRemovedItemTemporarily = (item: CartItem) => {
    lastRemoved.value = item

    setTimeout(() => {
      lastRemoved.value = undefined
    }, 15000)
  }

  const removeItem = (obj: CartItem) => {
    if (!process.client) {
      return null
    }

    let result = false

    const startingLength = cart.value.items.length
    if (obj) {
      setLastRemovedItemTemporarily(obj)
    }

    const toDeleteIdx = cart.value.items.findIndex((item) =>
      isTheSameItem(obj, item)
    )

    cart.value.items.splice(toDeleteIdx, 1)

    if (startingLength !== cart.value.items.length) {
      result = true
    }

    return result
  }

  const removeAddOn = (obj: CartItem) => {
    if (!process.client) {
      return null
    }

    const indexOfItemToRemoveAddOn = cart.value.items.findIndex((item) =>
      isTheSameItem(obj, item)
    )

    delete cart.value.items?.[indexOfItemToRemoveAddOn]?.addOn
  }

  const toggleGiftcardAddon = (obj: GiftcardCartItem, addon: GiftcardAddon) => {
    const item = findItem(obj)

    item.giftcardAddon = addon

    return true
  }

  watchEffect(() => {
    if (!hasGiftcard.value && cart.value.isGiftboxEnabled) {
      cart.value.isGiftboxEnabled = false
    }
  })

  return {
    cart,
    addItem,
    removeItem,
    removeAddOn,
    findItem,
    isInCart,
    updateItemQuantity,
    toggleGiftcardAddon,
  }
}
