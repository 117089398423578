import type { LocalBusiness } from 'schema-dts'
import type { Supplier } from '~/composables/types/event'

const getId = (supplier?: Supplier): string | null => {
  return supplier?.id || null
}

const getCreatedAt = (supplier?: Supplier): string => {
  return supplier?.created_at || ''
}

const getDescription = (supplier?: Supplier): string => {
  return supplier?.description || ''
}

const getShortDescription = (supplier?: Supplier): string => {
  return supplier?.description_summary || ''
}

const getAddress = (supplier?: Supplier): string => {
  return supplier?.address || ''
}

const getLogo = (supplier?: Supplier): string => {
  return (
    replaceSubdomainToProd(supplier?.logo?.data?.url)?.replace(
      '150x150',
      '150x0'
    ) || ''
  )
}

const getLogoMimeType = (supplier?: Supplier): string => {
  return supplier?.logo?.data?.mime_type || ''
}

const getHeaderLogo = (supplier?: Supplier): string => {
  const logo = supplier?.booking_solution_settings?.data?.header_logo?.data?.url

  if (logo && logo !== '') {
    return logo
  }

  return getLogo(supplier)
}

const getName = (supplier?: Supplier): string => {
  return supplier?.name || ''
}

const getPageTitle = (supplier?: Supplier): string => {
  return supplier?.page_title ?? getName(supplier)
}

const getUserName = (supplier?: Supplier): string => {
  if (!supplier?.user?.data?.first_name && !supplier?.user?.data?.last_name) {
    return ''
  }

  return `${supplier?.user.data.first_name} ${supplier?.user.data.last_name}`
}

const getPermalink = (supplier?: Supplier): string => {
  return supplier?.permalink || ''
}

const getWebsite = (supplier?: Supplier): string => {
  return supplier?.website || ''
}

const getSubdomain = (supplier?: Supplier): string => {
  return supplier?.subdomain || ''
}

const getHasGiftcardEmbedded = (supplier?: Supplier): boolean => {
  return Boolean(supplier?.giftcard_embedded)
}

const getReviewsAverage = (supplier?: Supplier): string | null => {
  const avg = supplier?.avg_review

  if (avg && avg !== '0.0') {
    return Number(avg).toFixed(1)
  }

  return null
}

const getReviewsTotal = (supplier?: Supplier): number => {
  return supplier?.total_reviews || 0
}

const getReviewsList = (supplier?: Supplier): any[] => {
  return supplier?.reviews?.data || []
}

const getSeoBusinessType = (supplier?: Supplier): LocalBusiness | null => {
  return supplier?.business_type || null
}

const isNameLikePageTitle = (supplier?: Supplier): boolean => {
  return supplier?.name === supplier?.page_title
}

const getKonfettiProfilePath = (supplier?: Supplier): string => {
  const permalink = getPermalink(supplier)
  return permalink ? `/p/${permalink}/` : ''
}

export const supplierGetters = {
  getId,
  getCreatedAt,
  getAddress,
  getDescription,
  getShortDescription,
  getLogo,
  getLogoMimeType,
  getName,
  getPageTitle,
  getUserName,
  getWebsite,
  getSubdomain,
  getHasGiftcardEmbedded,
  getPermalink,
  getReviewsAverage,
  getReviewsTotal,
  getReviewsList,
  getSeoBusinessType,
  isNameLikePageTitle,
  getHeaderLogo,
  getKonfettiProfilePath,
}
