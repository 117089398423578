import cloneDeep from 'lodash/cloneDeep'
import type { AlgoliaEventItem } from '~/composables/types/algolia'
import type { EventDescription } from '~/composables/types/event'
import { useIsSubdomain } from '~/composables/useSubdomain'

const WISHLIST_KEY_BASE = 'konfetti-wishlist' as string

type WishlistItemObject = AlgoliaEventItem | EventDescription

export interface WishlistItem {
  objectId: string
  objectType: 'AlgoliaEventItem' | 'EventDescription'
  object: WishlistItemObject
}

export interface Wishlist {
  items: WishlistItem[]
}

export const getEmptyWishlist = (): Wishlist =>
  cloneDeep<Wishlist>({
    items: [],
  })

export const useWishlist = () => {
  const isSubdomain = useIsSubdomain()
  const wishlistLocalStorageKey = () => {
    if (isSubdomain.value) {
      return `${WISHLIST_KEY_BASE}-${isSubdomain.value}`
    }
    return WISHLIST_KEY_BASE
  }

  const wishlist = useKftLocalStorage<Wishlist>(
    wishlistLocalStorageKey(),
    getEmptyWishlist()
  )

  const isTheSameItem = (
    obj: Partial<WishlistItem>,
    item: Partial<WishlistItem>
  ): boolean => {
    return item.objectId === obj.objectId
  }

  const findItem = (obj: WishlistItem): WishlistItem | undefined => {
    return wishlist.value.items.find((item: WishlistItem) =>
      isTheSameItem(obj, item)
    )
  }

  const isInWishlist = (obj: Partial<WishlistItem>): boolean => {
    return wishlist.value.items.some((item: WishlistItem) =>
      isTheSameItem(item, obj)
    )
  }

  const isAlgoliaEventItemOnWishlist = (item: AlgoliaEventItem): boolean => {
    return isInWishlist({
      objectId: algoliaEventGetters.fnGetHashedId(item),
    })
  }

  const addAlgoliaEventItem = (item: AlgoliaEventItem): void => {
    // Send GTM event no matter if it's already in wishlist
    sendAddToWishlist(item)
    // check if item is already in wishlist
    if (isAlgoliaEventItemOnWishlist(item)) {
      return
    }
    // push new item into cart
    wishlist.value.items.push({
      objectId: algoliaEventGetters.fnGetHashedId(item),
      objectType: 'AlgoliaEventItem',
      object: item,
    })
  }

  const removeItem = (obj: Partial<WishlistItem>) => {
    if (!process.client) {
      return null
    }

    let result = false

    const startingLength = wishlist.value.items.length

    const toDeleteIdx = wishlist.value.items.findIndex((item) =>
      isTheSameItem(obj, item)
    )

    wishlist.value.items.splice(toDeleteIdx, 1)

    if (startingLength !== wishlist.value.items.length) {
      result = true
    }

    return result
  }

  const removeAlgoliaEventItem = (item: AlgoliaEventItem) => {
    removeItem({ objectId: algoliaEventGetters.fnGetHashedId(item) })
  }

  return {
    wishlist,
    addAlgoliaEventItem,
    removeItem,
    removeAlgoliaEventItem,
    findItem,
    isInWishlist,
    isAlgoliaEventItemOnWishlist,
  }
}
