import type { CartItem } from '~/composables/types/cart'
import type { EventDescription } from '~/composables/types/event'
import type { GtmItemListId } from '~/composables/useKftGtm/types'
import type {
  AlgoliaEventItem,
  CategoryItem,
} from '~/composables/types/algolia'
import { sendViewItemListConfig } from '~/composables/useKftGtm/sendViewItemListConfig'
import { CartKeys, useCart } from '~/composables/useCart'
import {
  getEventAddOnId,
  getEventAddOnName,
  getEventAddOnUnitPriceAmount,
} from '~/utils/eventAddOnGetters'
import { eventGetters } from '~/utils/eventGetters'
import type { Wishlist } from '~/composables/useWishlist'

export const sendAddToCart = (cartItem: CartItem, event?: EventDescription) => {
  const $gtm = useKftGtm()

  if (!$gtm) {
    return
  }

  const gtmPrice =
    cartGetters.getUnitItemPrice(cartItem) || cartGetters.getItemPrice(cartItem)

  const eventQty = cartItem.quantity

  // GA4 object
  $gtm.pushToDataLayer({ ecommerce: null })

  const obj = {
    event: 'add_to_cart',
    ecommerce: {
      currency: 'EUR',
      items: [
        {
          item_name: cartGetters.getItemName(cartItem) || 'giftcard',
          id: cartGetters.getItemIdForGtm(cartItem),
          price: parseFloat(gtmPrice?.toFixed(2)) || gtmPrice,
          item_brand: cartItem?.supplier || 'konfetti',
          item_category: cartGetters.getItemMainCategory(cartItem),
          item_variant: cartGetters.getItemVariantForGtm(cartItem),
          quantity: eventQty,
        },
      ],
    },
  }

  const addon = cartGetters.getItemAddon(cartItem)
  if (addon) {
    const price = Number(getEventAddOnUnitPriceAmount(addon) || 0) / 100
    obj.ecommerce.items.push({
      item_name: getEventAddOnName(addon) || '',
      id: `addon-${getEventAddOnId(addon)!}`,
      price: parseFloat(price.toFixed(2)),
      item_brand: eventGetters.getSupplierName(event?.data) || '',
      item_category: 'ADD_ON',
      item_variant: 'ADD_ON',
      quantity: eventQty,
    })
  }

  $gtm.pushToDataLayer(obj)
}

export const sendProductDetailView = (event: EventDescription) => {
  const $gtm = useKftGtm()

  if (!$gtm) {
    return
  }

  const price = eventGetters.getDefaultPriceAmount(event)

  const gtmPrice = Number(price) / 100

  setTimeout(() => {
    $gtm.pushToDataLayer({ ecommerce: null })
    $gtm.pushToDataLayer({
      event: 'view_item',
      ecommerce: {
        currency: 'EUR',
        items: [
          {
            item_id: eventGetters.getId(event),
            item_brand: eventGetters.getSupplierName(event) || '',
            item_name: eventGetters.getTitle(event),
            item_category: eventGetters.getMainCategory(event),
            price: gtmPrice?.toFixed(2) || gtmPrice,
            quantity: 1,
          },
        ],
      },
    })
  }, 2200)
}

export const sendViewItemList = (
  itemListId: GtmItemListId,
  items: (EventDescription | CategoryItem)[]
) => {
  const $gtm = useKftGtm()

  if (!$gtm || !itemListId) {
    return
  }

  const { listName, parser } = sendViewItemListConfig[itemListId]

  const itemListData = {
    item_list_id: itemListId,
    item_list_name: listName,
  }

  const parsedItems = items.map((item, index) => ({
    ...parser(item),
    ...itemListData,
    index,
  }))

  setTimeout(() => {
    $gtm.pushToDataLayer({ ecommerce: null })
    $gtm.pushToDataLayer({
      event: 'view_item_list',
      ecommerce: {
        ...itemListData,
        items: parsedItems,
        currency: 'EUR',
      },
    })
  }, 1200)
}
export const sendViewCart = () => {
  const subdomain = useSubdomain()
  const { cart } = useCart(subdomain.value || CartKeys.GENERAL)

  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }
  $gtm.pushToDataLayer({ ecommerce: null })
  $gtm.pushToDataLayer({
    event: 'view_cart',
    ecommerce: {
      currency: 'EUR',
      value: cartGetters.getTotals(cart.value).total,
      items: cartGetters.getItemsForGtm(cart.value),
    },
  })
}
export const sendPrivateEventRequestForm = (
  requestLocation: string,
  cart_value: number
) => {
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }

  $gtm.pushToDataLayer({
    event: 'submit_perequest',
    request_location: requestLocation,
    cart_value,
  })
}
export const sendWaitlistForm = () => {
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }

  $gtm.pushToDataLayer({
    event: 'submit_waitlist',
  })
}

export const sendDateRequestForm = (
  requestLocation: string,
  cart_value: number
) => {
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }

  $gtm.pushToDataLayer({
    event: 'submit_daterequest',
    request_location: requestLocation,
    cart_value,
  })
}
export const sendDateRequestSidebarView = (requestLocation: string) => {
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }

  $gtm.pushToDataLayer({
    event: 'view_daterequest_sidebar',
    request_location: requestLocation,
  })
}

export const sendWaitlistSidebarView = () => {
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }

  $gtm.pushToDataLayer({
    event: 'view_waitlist_sidebar',
  })
}

export const sendGalleryShareIntent = (event: any, type: string) => {
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }
  $gtm.pushToDataLayer({
    event: 'share_event_modal',
    event_id: eventGetters.getId(event),
    event_name: eventGetters.getTitle(event),
    sharing_type: type,
  })
}

export const sendGalleryShareOption = (event: any, type: string) => {
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }
  $gtm.pushToDataLayer({
    event: 'share_event',
    event_id: eventGetters.getId(event),
    event_name: eventGetters.getTitle(event),
    sharing_options: type,
  })
}

export const sendGalleryShareNativeStatus = (event: any, status: string) => {
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }
  $gtm.pushToDataLayer({
    event: 'share_event_status',
    event_id: eventGetters.getId(event),
    event_name: eventGetters.getTitle(event),
    sharing_type: 'native',
    sharing_status: status,
  })
}

export const sendPricingOptions = (option: string) => {
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }

  $gtm.pushToDataLayer({
    event: 'select_pricing_plan',
    pricing_option: option,
  })
}

export const sendNewsletterSubscription = () => {
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }

  $gtm.pushToDataLayer({
    event: 'submit_newsletter',
  })
}

export const sendAddToWishlist = (item: AlgoliaEventItem) => {
  const $gtm = useKftGtm()

  if (!$gtm) {
    return
  }

  const gtmPrice = algoliaEventGetters.getPriceAmount(item)

  // GA4 object
  $gtm.pushToDataLayer({ ecommerce: null })
  const obj = {
    event: 'add_to_wishlist',
    ecommerce: {
      currency: 'EUR',
      items: [
        {
          item_name: algoliaEventGetters.fnGetTitle(item),
          id: algoliaEventGetters.fnGetHashedId(item),
          price: parseFloat(gtmPrice?.toFixed(2)) / 100,
          item_brand: algoliaEventGetters.fnGetSupplier(item) || 'konfetti',
          item_category: algoliaEventGetters.fnGetMainCategory(item),
        },
      ],
    },
  }

  $gtm.pushToDataLayer(obj)
}

export const sendViewWishlist = (wishlist: Wishlist) => {
  if (!wishlist?.items) return
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }
  $gtm.pushToDataLayer({ ecommerce: null })
  $gtm.pushToDataLayer({
    event: 'view_wishlist',
    ecommerce: {
      currency: 'EUR',
      items: algoliaEventGetters.getItemsForGtm(
        wishlist.items.map((item) => item.object)
      ),
    },
  })
}

export const sendFilterOpen = (filter: string) => {
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }

  $gtm.pushToDataLayer({
    event: 'open_filter',
    filter,
  })
}

export const sendFilterSelected = (filter: string, value: string) => {
  const $gtm = useKftGtm()
  if (!$gtm) {
    return
  }

  $gtm.pushToDataLayer({
    event: 'select_filter',
    filter,
    filter_option: value,
  })
}
