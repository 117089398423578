import dayjs from 'dayjs'
import type { PriceSpecification } from 'schema-dts'
import { supplierGetters } from './supplierGetters'
import type {
  EventDate,
  EventDescription,
  GalleryItem,
  Price,
} from '~/composables/types/event'
import type { PriceItem } from '~/composables/types/algolia'

const getId = (event?: EventDescription): string => {
  return event?.id || ''
}

const getNextInstance = (event: EventDescription): EventDate =>
  event?.next_instance

const getNextDateAvailable = (event?: EventDescription): string | null => {
  return event?.next_instance?.start || null
}

const getPermalink = (event?: EventDescription): string => {
  return event?.permalink || ''
}

const getNextDateAvailableEnd = (event?: EventDescription): string => {
  return event?.next_instance?.end || ''
}

const getNextDateAvailableId = (event?: EventDescription): string => {
  return event?.next_instance?.id || ''
}

const getNextDateAvailableDuration = (
  event?: EventDescription
): string | number | null => {
  return event?.next_instance?.duration || null
}

const getEventMinimumDuration = (event?: EventDescription): number | null => {
  return event?.min_duration || null
}

const getEventMaximumDuration = (event?: EventDescription): number | null => {
  return event?.max_duration || null
}

const getTitle = (event?: EventDescription | undefined): string => {
  return event?.title || ''
}

const getDescription = (event?: EventDescription): string => {
  return event?.description || ''
}

const getShortDescription = (event?: EventDescription): string => {
  return event?.description_summary || ''
}

const getCourseProgress = (event?: EventDescription): string => {
  return event?.course_progress || ''
}

const getCourseTarget = (event?: EventDescription): string => {
  return event?.course_target || ''
}

const getSubtitle = (event?: EventDescription): string => {
  return event?.subtitle || ''
}

const getTags = (event?: EventDescription): Array<any> => {
  return event?.tags?.data || []
}

const getDefaultDuration = (event?: EventDescription): string | number => {
  return event?.default_duration || ''
}

const getWhatIsIncludedSummary = (event?: EventDescription): string => {
  return event?.what_is_included_summary || ''
}

const getTeamEventsAndGiftCardsSummary = (event?: EventDescription): string => {
  return event?.team_events_and_gift_cards_summary || ''
}

const getCategories = (event?: EventDescription) => {
  return event?.categories?.data || event?.categories || ''
}

const getMainCategory = (event?: EventDescription): string | undefined => {
  const categories = event?.categories?.data
  if (categories && categories.length) {
    return categories[0].name || undefined
  }

  return undefined
}

const getMainCategorySlug = (event?: EventDescription): string | undefined => {
  const categories = event?.categories?.data
  if (categories && categories.length) {
    return categories[0].slug
  }

  return undefined
}

const getLocationSummary = (event?: EventDescription): string => {
  return event?.location_summary || ''
}

const getHasMultipleLocations = (event?: EventDescription): boolean => {
  return event?.multiple_locations || false
}

const getWhatToBring = (event?: EventDescription): string => {
  return event?.what_to_bring || ''
}

const getPrivateEventDescription = (event?: EventDescription): string => {
  return event?.private_event_description || ''
}

const getWhatIsIncluded = (event?: EventDescription): string => {
  return event?.what_is_included || ''
}

const getSpecialization = (event?: EventDescription): string => {
  return event?.specialization || ''
}

const getSupplier = (event?: EventDescription): any => {
  return event?.supplier?.data || null
}

const getSupplierLogo = (event?: EventDescription): string => {
  return supplierGetters.getLogo(getSupplier(event))
}

const getSupplierId = (event?: EventDescription) => {
  return getSupplier(event)?.id
}

const getSupplierCreatedAt = (event?: EventDescription): string => {
  return supplierGetters.getCreatedAt(getSupplier(event))
}

const getSupplierDescription = (event?: EventDescription): string => {
  return supplierGetters.getDescription(getSupplier(event))
}

const getSupplierShortDescription = (event?: EventDescription): string => {
  return supplierGetters.getShortDescription(getSupplier(event))
}

const getSupplierName = (event?: EventDescription): string => {
  return supplierGetters.getName(getSupplier(event))
}

const getSupplierUserName = (event?: EventDescription): string => {
  return supplierGetters.getUserName(getSupplier(event))
}

const getSupplierPermalink = (event?: EventDescription): string => {
  return supplierGetters.getPermalink(getSupplier(event))
}

const getSupplierWebsite = (event?: EventDescription): string => {
  return supplierGetters.getWebsite(getSupplier(event))
}

const getEventSupplierSubdomain = (event?: EventDescription): string => {
  return supplierGetters.getSubdomain(getSupplier(event))
}

const getNextInstanceInTimestamp = (event?: EventDescription): number => {
  const toTimestamp = new Date(event.next_instance?.start).getTime()
  return Math.floor(toTimestamp / 1000) || null
}

const getCoordinates = (event?: EventDescription): any => {
  return event?.address?.data?.coordinate || null
}

const getCoordinatesString = (event?: EventDescription) =>
  getCoordinates(event)?.join(', ')

const getFormattedAddress = (event?: EventDescription): string => {
  return event?.address?.data?.formatted || ''
}

const getAddressZipcode = (event?: EventDescription): string => {
  return event?.address?.data?.zipcode || ''
}

const getAddressLocality = (event?: EventDescription): any => {
  return event?.address?.data?.locality?.data || {}
}

const getAddressLocalityName = (event?: EventDescription): string => {
  return event?.address?.data?.locality?.data.name || ''
}

const getAddressLocalitySlug = (event?: EventDescription): string => {
  return event?.address?.data?.locality?.data.slug || ''
}

const getIsAddressRequired = (event?: EventDescription): boolean => {
  return event.required_address || false
}

const getMinimumPrice = (
  event?: EventDescription,
  from
): string | number | null => {
  return event?.min_price ? from + event?.min_price?.formatted || null : null
}

const getMinimumPriceAmount = (
  event?: EventDescription
): string | number | null => {
  return event?.min_price?.amount || null
}

const getMinimumPriceCurrency = (
  event?: EventDescription
): string | undefined => {
  return event?.min_price?.currency || 'EUR'
}

const getMaximumPrice = (
  event?: EventDescription,
  from
): string | number | null => {
  return event?.max_price ? from + event?.max_price?.formatted || null : null
}

const getMaximumPriceAmount = (
  event?: EventDescription
): string | number | null => {
  return event?.max_price?.amount || null
}

const getMaximumPriceCurrency = (
  event?: EventDescription
): string | number | null => {
  return event?.max_price?.currency || 'EUR'
}

const getPrice = (product): string | number | null => {
  return product?.price?.formatted || null
}

const getPriceAmount = (product): number | null => {
  return product?.price?.amount || product?.data?.price?.amount || null
}

const getTimezone = (event?: EventDescription) => {
  return event?.timezone || null
}

const getDefaultFormattedPrice = (
  event?: EventDescription
): string | number | null => {
  return event?.default_price?.formatted || null
}

const getDefaultPriceAmount = (
  event?: EventDescription
): string | number | null => {
  return event?.default_price?.amount || null
}

const getDefaultPriceObject = (event?: EventDescription): Price | null => {
  return event?.default_price || null
}

const getDefaultPriceCurrency = (
  event?: EventDescription
): string | undefined => {
  return event?.default_price?.currency || 'EUR'
}

const getGallery = (event?: EventDescription): GalleryItem[] =>
  event?.photos?.map((photo) => ({
    ...photo,
    cropped: replaceSubdomainToProd(photo?.cropped),
    original: replaceSubdomainToProd(photo?.original),
    thumb: replaceSubdomainToProd(photo?.thumb),
  })) || []

const getGalleryImages = (event?: EventDescription): GalleryItem[] => {
  const gallery = eventGetters.getGallery(event)
  return gallery?.filter((image) => image?.media_type === 'photo')
}

const getPrincipalImage = (
  event?: EventDescription
): GalleryItem | undefined => {
  const gallery = eventGetters.getGalleryImages(event)
  return gallery?.find((x) => x.isPrincipal)
}

const getFeaturedImages = (event?: EventDescription): GalleryItem[] => {
  const gallery = eventGetters.getGalleryImages(event)
  return gallery?.filter((x) => x.isFeatured) || []
}

const getNonFeaturedImages = (event?: EventDescription): GalleryItem[] => {
  const gallery = eventGetters.getGalleryImages(event)
  return gallery?.filter((x) => !x.isFeatured && !x.isPrincipal) || []
}

const getLandscapeVideo = (event?: EventDescription): GalleryItem | null => {
  const gallery = eventGetters.getGallery(event)
  return (
    gallery?.find(
      (x) => x.media_type === 'video' && x.orientation === 'landscape'
    ) || null
  )
}

const getPortraitVideo = (event?: EventDescription): GalleryItem | null => {
  const gallery = eventGetters.getGallery(event)
  return (
    gallery?.find(
      (x) => x.media_type === 'video' && x.orientation === 'portrait'
    ) || null
  )
}

const getFirstImageAlt = (event?: EventDescription): string => {
  const photos = event?.photos
  return photos && photos.length && photos.length > 0
    ? event.photos[0]?.alt
    : ''
}

const getFirstThumb = (event?: EventDescription): string => {
  const photos = event?.photos
  return photos && photos.length && photos.length > 0
    ? event.photos[0]?.thumb
    : undefined
}

const getAttendanceMode = (
  event?: EventDescription
): 'HYBRID' | 'IN_PERSON' | 'ONLINE' | 'NATIONWIDE' => {
  return event?.type || ''
}

const hasShippingCosts = (event: EventDescription): boolean =>
  event?.has_shipping_costs > 0

const getIsTeamEvent = (event?: EventDescription | null): boolean => {
  return Boolean(event?.is_team_event) || false
}

const getContentLocale = (event?: EventDescription): string => {
  return event?.content_locale || ''
}

const getMetaDescription = (event?: EventDescription): string => {
  return event?.meta_description || ''
}
const getLanguages = (event?: EventDescription): string[] | null => {
  return event?.languages || null
}

const getHasMobileClasses = (event?: EventDescription): boolean | undefined => {
  return event?.has_mobile_classes
}

const getReviewsAverage = (
  event?: EventDescription,
  locale = 'de-DE'
): string | null => {
  const avg = getReviewsRating(event)

  if (avg) {
    return new Intl.NumberFormat(locale, {
      maximumSignificantDigits: 2,
      minimumSignificantDigits: 2,
    }).format(avg)
  }

  return null
}

const getReviewsRating = (event?: EventDescription): number | null => {
  return event?.avg_review || null
}

const isGiftcardEvent = (event?: EventDescription): boolean =>
  event?.supplier?.data?.name === 'konfetti Gutscheine'

const isBoxEvent = (event?: EventDescription): boolean =>
  event?.type === 'NATIONWIDE'

const getReviewsTotal = (event?: EventDescription): number => {
  return event?.total_reviews || 0
}

const getReviewsList = (event?: EventDescription): any[] => {
  return event?.reviews?.data || []
}

// Reviews list sorted by Recency first (created_at decreasing)
const getReviewsListRecentFirst = (event?: EventDescription): any[] => {
  return getReviewsList(event)
    .slice()
    .sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )
}

const getIsApproved = (event?: EventDescription): boolean => {
  return event?.approved || false
}

const getStatus = (event?: EventDescription): string => {
  return event?.status || ''
}

const getEventIdFromPath = (path: string): string =>
  path?.split('-').pop() || ''

const isWaitlistActive = (event?: EventDescription): boolean =>
  event?.waitlist_active === 1 || false

const isDateRequestActive = (event?: EventDescription): boolean =>
  event?.request_active === 1 || false

const isDateRequestBookingSolution = (event?: EventDescription): boolean =>
  event?.request_booking_solution === 1 || false

/* isDateRequest Active on Booking solution */
const isDateRequestActiveBS = (
  event?: EventDescription,
  isSubdomain = false
): boolean =>
  isSubdomain
    ? isDateRequestActive(event) && isDateRequestBookingSolution(event)
    : isDateRequestActive(event)

const isPrivateDateRequestActive = (event?: EventDescription): boolean =>
  event?.request_private === 1 || false

const isDateRequestOrWaitingActive = (
  event?: EventDescription,
  isSubdomain = false
): boolean =>
  isDateRequestActiveBS(event, isSubdomain) || isWaitlistActive(event)

const isDateRequestActiveWithSubdomainCheck = (
  event?: EventDescription,
  isSubdomain = false
): boolean =>
  isDateRequestActiveBS(event, isSubdomain) || isWaitlistActive(event)

const getDateRequestMinAdvanceQuantity = (event?: EventDescription): number =>
  event?.request_min_advance || 0

const getDateRequestMinAdvanceUnit = (event?: EventDescription): string =>
  event?.request_min_advance_type || ''

const getMinDateRequestTickets = (event?: EventDescription): number =>
  event?.request_min_tickets || 1

const getMaxDateRequestTickets = (event?: EventDescription): number =>
  event?.request_max_tickets || 50

const getDateRequestAvailableDays = (event?: EventDescription): number[] =>
  event?.request_days || []

const getMaxDate = (event?: EventDescription): string => {
  return event?.max_date
}

const getNeedsMajority = (event: EventDescription): boolean =>
  event?.needs_majority

const getHasPromotion = (event: EventDescription): boolean => {
  return event?.has_promotions
}

const getRegularPrice = (item: EventDescription): PriceItem | undefined => {
  return item?.original_price || item?.default_price || 0
}

const getSpecialPrice = (item: EventDescription): PriceItem | undefined => {
  return getHasPromotion(item) ? item?.default_price : undefined
}

const getRegularPriceAmount = (item: EventDescription): number | undefined => {
  if (!item) return undefined
  return parseInt(getRegularPrice(item)?.amount || '0')
}

const getSpecialPriceAmount = (item: EventDescription): number | undefined => {
  if (!item) return undefined
  return parseInt(getSpecialPrice(item)?.amount || '0')
}

const getPromoDaysLeft = (item?: EventDescription): number | undefined => {
  return item?.activePromotion?.data?.days_left
}

const getPromoEndDate = (
  item?: EventDescription,
  format?: string
): string | undefined => {
  if (format)
    return dayjs(item?.activePromotion?.data?.period_ends_at)
      .tz('Europe/Berlin')
      .format(format)
  return item?.activePromotion?.data?.period_ends_at || undefined
}

const getOriginalPrice = (item?: EventDescription): Price | undefined => {
  return item?.original_price
}
const getOriginalPriceAmount = (item: EventDescription): number | undefined => {
  if (!item) return undefined
  return parseInt(getOriginalPrice(item)?.amount || '0')
}

const getSchemaPriceSpecification = (
  item: EventDescription
): PriceSpecification[] =>
  eventGetters.getHasPromotion(item)
    ? eventGetters.getSchemaPromotionPrices(item)
    : [eventGetters.getSchemaBasePrice(item)]

const getSchemaPromotionPrices = (item: EventDescription): any => [
  {
    '@type': 'PriceSpecification',
    price: eventGetters.getOriginalPriceAmount(item) / 100,
    priceCurrency: eventGetters.getDefaultPriceCurrency(item),
  } as PriceSpecification,
  {
    '@type': 'PriceSpecification',
    price: eventGetters.getSpecialPriceAmount(item) / 100,
    priceCurrency: eventGetters.getDefaultPriceCurrency(item),
    validThrough: eventGetters.getPromoEndDate(item, 'YYYY-MM-DD'),
  } as PriceSpecification,
]

const getSchemaBasePrice = (item: EventDescription): PriceSpecification => ({
  '@type': 'PriceSpecification',
  price: eventGetters.getDefaultPriceAmount(item) / 100,
  priceCurrency: eventGetters.getDefaultPriceCurrency(item),
})

export const eventGetters = {
  getId,
  getTitle,
  getDescription,
  getShortDescription,
  getSubtitle,
  getTags,
  getEventIdFromPath,
  getIsAddressRequired,
  getDefaultDuration,
  getWhatIsIncludedSummary,
  getCategories,
  getMainCategory,
  getMainCategorySlug,
  getTeamEventsAndGiftCardsSummary,
  getLocationSummary,
  getHasMultipleLocations,
  getWhatToBring,
  getPrivateEventDescription,
  getWhatIsIncluded,
  getSpecialization,
  getSupplierCreatedAt,
  getSupplierDescription,
  getSupplierShortDescription,
  getCourseProgress,
  getCourseTarget,
  getSupplierName,
  getSupplierWebsite,
  getEventSupplierSubdomain,
  getNextInstanceInTimestamp,
  getCoordinates,
  getCoordinatesString,
  getFormattedAddress,
  getAddressZipcode,
  getAddressLocality,
  getAddressLocalityName,
  getAddressLocalitySlug,
  getMinimumPrice,
  getMinimumPriceAmount,
  getMinimumPriceCurrency,
  getMaximumPrice,
  getMaximumPriceAmount,
  getMaximumPriceCurrency,
  getGallery,
  getGalleryImages,
  getPrincipalImage,
  getFeaturedImages,
  getLandscapeVideo,
  getPortraitVideo,
  getPrice,
  getNextInstance,
  getNextDateAvailable,
  getNextDateAvailableEnd,
  getNextDateAvailableDuration,
  getNextDateAvailableId,
  getEventMinimumDuration,
  getFirstImageAlt,
  getFirstThumb,
  getPriceAmount,
  getAttendanceMode,
  getSupplierLogo,
  getSupplierId,
  getPermalink,
  getIsTeamEvent,
  getContentLocale,
  getDefaultFormattedPrice,
  getMetaDescription,
  getTimezone,
  getEventMaximumDuration,
  getDefaultPriceAmount,
  getDefaultPriceObject,
  getDefaultPriceCurrency,
  getLanguages,
  getSupplier,
  getReviewsAverage,
  getReviewsRating,
  getReviewsTotal,
  getReviewsList,
  getReviewsListRecentFirst,
  getIsApproved,
  getStatus,
  getSupplierUserName,
  getSupplierPermalink,
  getNonFeaturedImages,
  isGiftcardEvent,
  isBoxEvent,
  isWaitlistActive,
  isDateRequestActive,
  isDateRequestBookingSolution,
  isDateRequestOrWaitingActive,
  isDateRequestActiveWithSubdomainCheck,
  isDateRequestActiveBS,
  isPrivateDateRequestActive,
  hasShippingCosts,
  getMaxDateRequestTickets,
  getMinDateRequestTickets,
  getDateRequestAvailableDays,
  getNeedsMajority,
  getDateRequestMinAdvanceQuantity,
  getDateRequestMinAdvanceUnit,
  getHasMobileClasses,
  getMaxDate,
  getRegularPrice,
  getSpecialPrice,
  getRegularPriceAmount,
  getSpecialPriceAmount,
  getPromoDaysLeft,
  getPromoEndDate,
  getHasPromotion,
  getOriginalPrice,
  getOriginalPriceAmount,
  getSchemaPriceSpecification,
  getSchemaPromotionPrices,
  getSchemaBasePrice,
}
